.startseite {
    font-family: FilsonPro, sans-serif;

    &__introtext {
        color: map-get($farben, rot);
    }

    &__text {

        a {
            color: black;
        }
    }

}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .startseite {
    
        &__introtext {
            @include abstand($layout, margin, alle, 87, 0, 103, 0);
            @include schriftart($layout, 60, 51, 700);
        }
        
        &__text {

            h2 {
                @include abstand($layout, padding, bottom, 53);
                @include schriftart($layout, 28, 31, 700);
            }
            
            h3 {
                @include abstand($layout, padding, bottom, 40);
                @include schriftart($layout, 20, 27, 700);
            }
            
            p {
                @include abstand($layout, padding, bottom, 35);
                @include schriftart($layout, 20, 27, 400);
            }
            
            a {
                @include abstand($layout, padding, bottom, 35);
                @include schriftart($layout, 20, 27, 700);
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .startseite {

        &__introtext {
            @include abstand($layout, margin, bottom, 458);
            @include schriftart($layout, 300, 252, 700);
            margin-left: spalten($layout, -3, 9, 2, 0);
        }
        
        &__text {
            margin-left: spalten($layout, -1, 9, 2, 1);

            h2 {
                @include abstand($layout, padding, bottom, 50);
                @include schriftart($layout, 70, 75, 700);
            }
            
            h3 {
                @include abstand($layout, padding, bottom, 40);
                @include schriftart($layout, 28, 32, 700);
            }
            
            p {
                @include abstand($layout, padding, bottom, 40);
                @include schriftart($layout, 28, 32, 400);
            }
            
            a {
                @include abstand($layout, padding, bottom, 40);
                @include schriftart($layout, 28, 32, 700);
            }
        }
    }
}