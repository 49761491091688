.titel {
    font-family: FilsonPro, sans-serif;
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .titel {
        @include abstand($layout, margin, bottom, 91);
        @include schriftart($layout, 34, 34, 400);
        display: block;
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .titel {
        @include abstand($layout, margin, bottom, 190);
        display: none;
    }

}