.text-schmal {
    font-family: Clarendon, serif;
    display: flex;

    &:last-child {
        margin-bottom: 0;
    }

    &__titel {
        font-family: FilsonPro, sans-serif;
        font-weight: 400;
    }

    // Ein beliebiges erstes Kindelement bekommt den margin 0
    // Beliebig, weil nicht feststeht, was für ein Kindelement es ist (Matrixfeld)
    &__text {
        & > *:first-child {
            margin-top: 0;
        }
    }

    a {
        color: black;
        font-family: FilsonPro, sans-serif;
        font-weight: 700;

        .body--kontakt & {
            font-family: Clarendon, serif;
            font-weight: 400;
            text-decoration: none;
        }
        
        &:hover {
            color: map-get($farben, rot);
        }

        strong {
            font-family: FilsonPro, sans-serif;
            font-weight: 700;
        }

        ul & {
            color: map-get($farben, rot);
        }
    }

    strong {
        font-family: FilsonPro, sans-serif;
        font-weight: 700;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .text-schmal {
        @include abstand($layout, margin, alle, 40, 0, 40, 0);
        @include schriftart($layout, 20, 27, 400);
        flex-direction: column;

        &__bild {
            @include abstand($layout, margin, alle, 0, 0, 40, 0);
            order: 1;

            .text-schmal--0 & {
                margin-left: groesse($layout, -26);
            }

            .text-schmal--1 & {
                margin-right: groesse($layout, -26);
            }
        }

        &__container {
            order: 3;
        }

        &__titel {
            @include abstand($layout, margin, alle, 0, 0, 40, 0);
            @include schriftart($layout, 26, 28);
            order: 2;
        }

        &__text {

            p {
                @include abstand($layout, margin, top, 18);
            }

            ul {
                @include abstand($layout, margin, top, 18);
            }

            li {
                @include abstand($layout, padding, alle, 0, 25, 0, 17);

                &::before {
                    top: groesse($layout, -1);
                }
            }
        }

        &__links {
            @include abstand($layout, margin, top, 60);

            ul {
                // @include abstand($layout, margin, top, 75);
                @include abstand($layout, margin, bottom, -15);
            }

            li {
                @include abstand($layout, padding, alle, 0, 0, 15, 17);

                &::before {
                    top: groesse($layout, -1);
                }
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .text-schmal {
        @include abstand($layout, margin, alle, 190, 0, 190, 0);
        @include schriftart($layout, 22, 30, 400);
        flex-wrap: wrap;
        justify-content: space-between;

        &__bild {
            width: spalten($layout, 5, 9, 0, 0);

            .text-schmal--0 & {
                margin-left: groesse($layout, -71);
                order: 2;
            }

            .text-schmal--1 & {
                margin-right: groesse($layout, -71);
                order: 3;
            }
        }

        &__container {
            width: spalten($layout, 4, 9, 0, 0);

            .text-schmal--0 & {
                order: 3;
            }

            .text-schmal--1 & {
                order: 2;
            }
        }

        &__titel {
            @include abstand($layout, margin, bottom, 54);
            @include schriftart($layout, 34, 36);
            order: 1;
            width: 100%;

            .text-schmal--0 & {
                padding-left: spalten($layout, 5, 9, 0, 1);
            }
        }

        &__text {

            p {
                @include abstand($layout, margin, top, 20);
            }

            ul {
                @include abstand($layout, margin, top, 20);

                li {
                    @include abstand($layout, padding, alle, 0, 0, 0, 17);

                    &::before {
                        top: groesse($layout, -1);
                    }
                }
            }

        }

        &__links {
            @include abstand($layout, margin, top, 65);

            ul {
                @include abstand($layout, margin, bottom, -15);

                li {
                    @include abstand($layout, padding, alle, 0, 0, 15, 17);

                    &::before {
                        top: groesse($layout, -1);
                    }
                }
            }

        }
    }
}