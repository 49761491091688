.hymne-text {
    font-family: FilsonPro, sans-serif;
    color: white;

    &:last-child {
        margin-bottom: 0;
    }

    .body[data-background="weiss"] & {
        background: linear-gradient(to bottom, #e8aeae 0%, #72bbbe 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .hymne-text {
        @include abstand($layout, margin, alle, 110 - 3, 0, 110 - 3, 0);
        @include abstand($layout, padding, alle, 3, 0, 3, 0);
        @include schriftart($layout, 54, 48, 700);
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .hymne-text {
        @include abstand($layout, margin, alle, 190 - 5, 0, 190 - 5, 0);
        // i wird abgeschnitten aufgrund von text-fill-color: transparent. Lösung:
        @include abstand($layout, padding, alle, 5, 0, 5, 0);
        @include schriftart($layout, 128, 115, 700);
        margin-left: spalten($layout, -3, 9, 2, 0);
    }
}