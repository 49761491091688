.main {
    position: relative;
    hyphens: auto;

    & > * {
        margin-top: 0px;
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .main {
        @include abstand($layout, padding, alle, 114, 26, 110, 26);
        min-height: 100vh;
    }
}

@include mediaquery(desktop) {
    
    $layout: desktop;

    .main {
        @include abstand($layout, padding, alle, 410, 140, 190, 554);
        min-height: 100vh;
    }
}