.introtext {
    font-family: Clarendon;
    color: black;

    .body[data-background="100%"] & {
        color: map-get($farben, rot);
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .introtext {
        @include abstand($layout, margin, bottom, 40);
        @include schriftart($layout, 23, 26, 400);
    }
}


@include mediaquery(desktop) {
    
    $layout: desktop;
    
    .introtext {
        @include abstand($layout, margin, bottom, 190);
        @include schriftart($layout, 48, 56, 400);
    }
}