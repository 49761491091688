.ausflugsziel {

    &__ausflugsziel {
        display: flex;
        flex-direction: column;
    }

    &__verb,
    &__titel {
        font-family: FilsonPro, sans-serif;
    }

    &__text {
        font-family: Clarendon;
        color: #000;
    }

    &__link {
        color: #000;
        text-decoration-color: #000;
    }

    &__link-text {
        font-family: FilsonPro, sans-serif;
        font-weight: 700;
    }

    &__logo-container {
        margin-top: auto;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .ausflugsziel {

        &__ausflugsziel {
            @include abstand($layout, margin, alle, 0, 0, 26, 0);
            @include abstand($layout, padding, alle, 10, 10, 30, 10);
            background-color: #fff;
        }

        &__verb {
            @include abstand($layout, margin, alle, 26, 20, 0, 16);
            @include schriftart($layout, 26, 28, 400);
        }

        &__titel {
            @include abstand($layout, margin, alle, 0, 20, 15, 16);
            @include schriftart($layout, 26, 28, 700);
        }

        &__text {
            @include abstand($layout, margin, alle, 0, 30, 15, 16);
            @include schriftart($layout, 18, 22, 400);
        }

        &__link-text {
            @include schriftart($layout, 18, 22);
            @include abstand($layout, margin, alle, 25, 30, 75, 16);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .ausflugsziel {
        display: flex;
        flex-wrap: wrap;
        @include abstand($layout, margin, left, -16);

        &__ausflugsziel {
            @include abstand($layout, margin, alle, 0, 0, 35, 0);
            @include abstand($layout, padding, alle, 10, 10, 30, 10);
            background-color: #fff;
            width: spalten($layout, 3, 9, 1, 0);
            margin-left: spalten($layout, 0, 9, 0, 2);
        }

        &__verb {
            @include abstand($layout, margin, alle, 32, 21, 0, 17);
            @include schriftart($layout, 34, 36, 400);
        }

        &__titel {
            @include abstand($layout, margin, alle, 0, 21, 15, 17);
            @include schriftart($layout, 34, 36, 700);
        }

        &__text {
            @include abstand($layout, margin, alle, 0, 34, 15, 17);
            @include schriftart($layout, 16, 20, 400);
        }

        &__link-text {
            @include schriftart($layout, 16, 20);
            @include abstand($layout, margin, alle, 25, 34, 95, 17);
        }

        &__logo-container {
            display: flex;
            justify-content: center;
        }

        &__logo {
            display: flex;
            justify-content: center;

            &--gross {
                width: groesse($layout, 255);
            }

            &--klein {
                width: groesse($layout, 92);
            }
        }
    }
}