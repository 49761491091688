.menue {
    font-family: FilsonPro, sans-serif;
    font-weight: 400;

    &__logo-text {
        display: none;

        .body--startseite & {
            display: block;
        }
    }

    &__logo,
    &__logo-text {
        color: black;
        font-family: Clarendon;
    }

    &__hymne,
    &__spenden {
        cursor: pointer;
    }

    &__links {
        align-items: flex-start;
    }

    &__link {
        cursor: pointer;
        color: black;

        .body[data-background="100%"] & {
            color: white;

            &:hover {
                color: map-get($farben, rot);
            }
        }

        &[data-menue-aktiv="true"] {
            color: map-get($farben, rot) !important;
        }

        &:hover {
            color: map-get($farben, rot);
        }
    }

    &__spenden {
        align-items: center;
        background-color: white;
        color: black;
        border-radius: 1000px;
        display: flex;
        justify-content: center;
        text-align: center;
        text-decoration: none;

        &:hover {
            background-color: #72BBBE;
            color: white;
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .menue {
        @include abstand($layout, padding, alle, 0, 26, 0, 26);
        display: none;
        height: calc(var(--vh, 1vh) * 100);
        left: 0;
        overflow-y: scroll;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;

        .body[data-menue-aktiv="true"] & {
            display: flex;
            flex-direction: column;
            background: linear-gradient(to bottom, #e2a2a2 0%, #72bbbe 100vh);
        }

        &__hamburger {
            @include abstand($layout, padding, alle, 10, 10, 10, 10);
            background-color: transparent;
            border: none;
            position: fixed;
            right: groesse($layout, 26 - 10);
            top: groesse($layout, 26 - 10);
            z-index: 1001;
        }
        
        &__hamburger-svg {
            width: 100%;
            height: 100%;

            &--inaktiv {
                .body[data-menue-aktiv="true"] & {
                    display: none;
                }
            }
            
            &--aktiv {
                display: none;

                .body[data-menue-aktiv="true"] & {
                    display: block;
                }
            }

        }

        &__logo-text {
            @include schriftart($layout, 16, 18, 400);
            position: absolute;
            top: groesse($layout, 98);
            left: groesse($layout, 26);
            z-index: 1;
        }

        &__logo {
            @include schriftart($layout, 24, 20, 400);
            text-decoration: none;
            position: absolute;
            top: groesse($layout, 24);
            left: groesse($layout, 26);
            z-index: 1;
            
            .body--startseite & {
                @include schriftart($layout, 35, 29, 400);
            }
        }

        &__hymne {
            display: none;
            position: absolute;
            top: groesse($layout, 18);
            left: groesse($layout, 30);
            z-index: 10010;

            .body[data-menue-aktiv="true"] & {
                display: block;
            }
        }

        &__hymne-svg {
            width: groesse($layout, 121);
            height: groesse($layout, 115);
        }

        &__spenden-gruppe {
            display: flex;
            flex-direction: row-reverse;
            width: 100%;
            z-index: 1;
            position: absolute;
            bottom: groesse($layout, 47);
            left: 0;
        }

        &__spenden {
            @include abstand($layout, margin, top, 14);
            @include schriftart($layout, 24, 25, 700);
            bottom: 0;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
            height: groesse($layout, 152);
            order: 3;
            flex-shrink: 0;
            position: absolute;
            right: 0;
            width: groesse($layout, 152);

        }

        &__gruppe {
            display: flex;
            flex-direction: column;

            &--1-1 {
                // @include schriftart($layout, 34, 45);
                // vorschlag: -5%
                @include schriftart($layout, 32, 43);
            }
            
            &--1-2 {
                // @include abstand($layout, margin, top, 24);
                @include abstand($layout, margin, top, 22);
                // @include schriftart($layout, 26, 39);
                @include schriftart($layout, 24, 37);
            }
            
            &--1-3 {
                // @include abstand($layout, margin, top, 130);
                @include abstand($layout, margin, top, 123);
                // @include schriftart($layout, 18, 20, 400);
                @include schriftart($layout, 17, 19);
            }
        }

        &__links {
            // @include abstand($layout, margin, alle, 191 - 5, 0, 47, 0);
            // vorschlag:
            @include abstand($layout, margin, alle, 150, 0, 47, 0);
            display: flex;
            flex-direction: column;
            position: relative;
        }

        &__link {
            text-decoration: none;
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .menue {
        height: 100%;
        width: 100%;

        &__logo-text {
            @include schriftart($layout, 28, 32, 400);
            position: absolute;
            top: groesse($layout, 68);
            right: groesse($layout, 576);
            z-index: 1;

            .body--startseite & {
                position: fixed;
            }
        }

        &__logo {
            @include schriftart($layout, 70, 59, 400);
            text-decoration: none;
            position: absolute;
            top: groesse($layout, 50);
            right: groesse($layout, 92);
            z-index: 1;

            .body--startseite & {
                position: fixed;
            }
        }

        &__logo-text {
            text-align: right;
        }

        &__hymne {
            position: absolute;
            top: groesse($layout, 223);
            right: groesse($layout, 92);
            z-index: 1;

            .body--startseite & {
                position: fixed;
            }
        }

        &__hymne-svg {
            width: groesse($layout, 121);
            height: groesse($layout, 115);
        }

        &__spenden {
            @include abstand($layout, margin, top, 105);
            @include schriftart($layout, 42, 44, 700);
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
            height: groesse($layout, 264);
            width: groesse($layout, 264);
            z-index: 1;
        }

        &__links {
            left: groesse($layout, 55);
            position: fixed;
            top: groesse($layout, 50);
            z-index: 1;
            height: calc(100vh - groesse($layout, 50));
        }

        &__gruppe {
            &--1-1 {
                @include schriftart($layout, 34, 41);
            }
            
            &--1-2 {
                @include abstand($layout, margin, top, 24);
                @include schriftart($layout, 26, 32, 400);
            }

            &--1-3 {
                @include schriftart($layout, 18, 20, 400);
                bottom: groesse($layout, 61);
                left: 0;
                position: absolute;
            }
        }

        &__link {
            display: inherit;
            text-decoration: none;
            position: relative;

            &--aktiv {
                font-weight: 500;
            }

        }

        &__hamburger {
            display: none;
        }
    }
}