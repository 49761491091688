@charset "UTF-8";
.bild {
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:after, *:before {
  box-sizing: border-box;
}

body {
  background-color: white;
  margin: 0 auto;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  text-decoration-skip-ink: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

#seite:after {
  content: "";
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -100;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  position: relative;
}
li::before {
  content: "–";
  left: 0;
  position: absolute;
}

sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}

svg {
  padding: 1px 1px 1px 1px;
}

*:focus:not(:focus-visible) {
  outline: none !important;
}

*:focus-visible {
  outline: 2px solid black;
}

img {
  color: transparent;
  display: block;
  line-height: 1em;
  text-indent: -9999px;
}

@font-face {
  font-family: Clarendon;
  src: url("/fonts/ClarendonTextPro/ClarendonTextPro-Regular.woff");
  font-weight: 400;
}
/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2023 MyFonts Inc. */
@font-face {
  font-family: "FilsonPro";
  src: url("../fonts/FilsonProRegular/font.woff2") format("woff2"), url("../fonts/FilsonProRegular/font.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "FilsonPro";
  src: url("../fonts/FilsonProBold/font.woff2") format("woff2"), url("../fonts/FilsonProBold/font.woff") format("woff");
  font-weight: 700;
}
.ausflugsziel__ausflugsziel {
  display: flex;
  flex-direction: column;
}
.ausflugsziel__verb, .ausflugsziel__titel {
  font-family: FilsonPro, sans-serif;
}
.ausflugsziel__text {
  font-family: Clarendon;
  color: #000;
}
.ausflugsziel__link {
  color: #000;
  text-decoration-color: #000;
}
.ausflugsziel__link-text {
  font-family: FilsonPro, sans-serif;
  font-weight: 700;
}
.ausflugsziel__logo-container {
  margin-top: auto;
}

@media (max-width: 799px) {
  .ausflugsziel__ausflugsziel {
    margin: 0px 0px 26px 0px;
    padding: 10px 10px 30px 10px;
    background-color: #fff;
  }
  .ausflugsziel__verb {
    margin: 26px 20px 0px 16px;
    font-size: 26px;
    line-height: 28px;
    font-weight: 400;
  }
  .ausflugsziel__titel {
    margin: 0px 20px 15px 16px;
    font-size: 26px;
    line-height: 28px;
    font-weight: 700;
  }
  .ausflugsziel__text {
    margin: 0px 30px 15px 16px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
  }
  .ausflugsziel__link-text {
    font-size: 18px;
    line-height: 22px;
    margin: 25px 30px 75px 16px;
  }
}
@media (min-width: 800px) {
  .ausflugsziel {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.83vw;
  }
  .ausflugsziel__ausflugsziel {
    margin: 0vw 0vw 1.82vw 0vw;
    padding: 0.52vw 0.52vw 1.56vw 0.52vw;
    background-color: #fff;
    width: calc((100% - 0vw - 7.47vw) / 9 * 3 + 1.66vw + 0vw);
    margin-left: calc((100% - 0vw - 6.64vw) / 9 * 0 + 0.83vw + 0vw);
  }
  .ausflugsziel__verb {
    margin: 1.67vw 1.09vw 0vw 0.89vw;
    font-size: 1.77vw;
    line-height: 1.88vw;
    font-weight: 400;
  }
  .ausflugsziel__titel {
    margin: 0vw 1.09vw 0.78vw 0.89vw;
    font-size: 1.77vw;
    line-height: 1.88vw;
    font-weight: 700;
  }
  .ausflugsziel__text {
    margin: 0vw 1.77vw 0.78vw 0.89vw;
    font-size: 0.83vw;
    line-height: 1.04vw;
    font-weight: 400;
  }
  .ausflugsziel__link-text {
    font-size: 0.83vw;
    line-height: 1.04vw;
    margin: 1.3vw 1.77vw 4.95vw 0.89vw;
  }
  .ausflugsziel__logo-container {
    display: flex;
    justify-content: center;
  }
  .ausflugsziel__logo {
    display: flex;
    justify-content: center;
  }
  .ausflugsziel__logo--gross {
    width: 13.28vw;
  }
  .ausflugsziel__logo--klein {
    width: 4.79vw;
  }
}
.hymne-text {
  font-family: FilsonPro, sans-serif;
  color: white;
}
.hymne-text:last-child {
  margin-bottom: 0;
}
.body[data-background=weiss] .hymne-text {
  background: linear-gradient(to bottom, #e8aeae 0%, #72bbbe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 799px) {
  .hymne-text {
    margin: 107px 0px 107px 0px;
    padding: 3px 0px 3px 0px;
    font-size: 54px;
    line-height: 48px;
    font-weight: 700;
  }
}
@media (min-width: 800px) {
  .hymne-text {
    margin: 9.64vw 0vw 9.64vw 0vw;
    padding: 0.26vw 0vw 0.26vw 0vw;
    font-size: 6.67vw;
    line-height: 5.99vw;
    font-weight: 700;
    margin-left: calc((100% - 0vw - 8.3vw) / 9 * -3 - 3.32vw + 0vw);
  }
}
.introtext {
  font-family: Clarendon;
  color: black;
}
.body[data-background="100%"] .introtext {
  color: #E61512;
}

@media (max-width: 799px) {
  .introtext {
    margin-bottom: 40px;
    font-size: 23px;
    line-height: 26px;
    font-weight: 400;
  }
}
@media (min-width: 800px) {
  .introtext {
    margin-bottom: 9.9vw;
    font-size: 2.5vw;
    line-height: 2.92vw;
    font-weight: 400;
  }
}
.menue {
  font-family: FilsonPro, sans-serif;
  font-weight: 400;
}
.menue__logo-text {
  display: none;
}
.body--startseite .menue__logo-text {
  display: block;
}
.menue__logo, .menue__logo-text {
  color: black;
  font-family: Clarendon;
}
.menue__hymne, .menue__spenden {
  cursor: pointer;
}
.menue__links {
  align-items: flex-start;
}
.menue__link {
  cursor: pointer;
  color: black;
}
.body[data-background="100%"] .menue__link {
  color: white;
}
.body[data-background="100%"] .menue__link:hover {
  color: #E61512;
}
.menue__link[data-menue-aktiv=true] {
  color: #E61512 !important;
}
.menue__link:hover {
  color: #E61512;
}
.menue__spenden {
  align-items: center;
  background-color: white;
  color: black;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  text-align: center;
  text-decoration: none;
}
.menue__spenden:hover {
  background-color: #72BBBE;
  color: white;
}

@media (max-width: 799px) {
  .menue {
    padding: 0px 26px 0px 26px;
    display: none;
    height: calc(var(--vh, 1vh) * 100);
    left: 0;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
  .body[data-menue-aktiv=true] .menue {
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, #e2a2a2 0%, #72bbbe 100vh);
  }
  .menue__hamburger {
    padding: 10px 10px 10px 10px;
    background-color: transparent;
    border: none;
    position: fixed;
    right: 16px;
    top: 16px;
    z-index: 1001;
  }
  .menue__hamburger-svg {
    width: 100%;
    height: 100%;
  }
  .body[data-menue-aktiv=true] .menue__hamburger-svg--inaktiv {
    display: none;
  }
  .menue__hamburger-svg--aktiv {
    display: none;
  }
  .body[data-menue-aktiv=true] .menue__hamburger-svg--aktiv {
    display: block;
  }
  .menue__logo-text {
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    position: absolute;
    top: 98px;
    left: 26px;
    z-index: 1;
  }
  .menue__logo {
    font-size: 24px;
    line-height: 20px;
    font-weight: 400;
    text-decoration: none;
    position: absolute;
    top: 24px;
    left: 26px;
    z-index: 1;
  }
  .body--startseite .menue__logo {
    font-size: 35px;
    line-height: 29px;
    font-weight: 400;
  }
  .menue__hymne {
    display: none;
    position: absolute;
    top: 18px;
    left: 30px;
    z-index: 10010;
  }
  .body[data-menue-aktiv=true] .menue__hymne {
    display: block;
  }
  .menue__hymne-svg {
    width: 121px;
    height: 115px;
  }
  .menue__spenden-gruppe {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    z-index: 1;
    position: absolute;
    bottom: 47px;
    left: 0;
  }
  .menue__spenden {
    margin-top: 14px;
    font-size: 24px;
    line-height: 25px;
    font-weight: 700;
    bottom: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    height: 152px;
    order: 3;
    flex-shrink: 0;
    position: absolute;
    right: 0;
    width: 152px;
  }
  .menue__gruppe {
    display: flex;
    flex-direction: column;
  }
  .menue__gruppe--1-1 {
    font-size: 32px;
    line-height: 43px;
  }
  .menue__gruppe--1-2 {
    margin-top: 22px;
    font-size: 24px;
    line-height: 37px;
  }
  .menue__gruppe--1-3 {
    margin-top: 123px;
    font-size: 17px;
    line-height: 19px;
  }
  .menue__links {
    margin: 150px 0px 47px 0px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .menue__link {
    text-decoration: none;
  }
}
@media (min-width: 800px) {
  .menue {
    height: 100%;
    width: 100%;
  }
  .menue__logo-text {
    font-size: 1.46vw;
    line-height: 1.67vw;
    font-weight: 400;
    position: absolute;
    top: 3.54vw;
    right: 30vw;
    z-index: 1;
  }
  .body--startseite .menue__logo-text {
    position: fixed;
  }
  .menue__logo {
    font-size: 3.65vw;
    line-height: 3.07vw;
    font-weight: 400;
    text-decoration: none;
    position: absolute;
    top: 2.6vw;
    right: 4.79vw;
    z-index: 1;
  }
  .body--startseite .menue__logo {
    position: fixed;
  }
  .menue__logo-text {
    text-align: right;
  }
  .menue__hymne {
    position: absolute;
    top: 11.61vw;
    right: 4.79vw;
    z-index: 1;
  }
  .body--startseite .menue__hymne {
    position: fixed;
  }
  .menue__hymne-svg {
    width: 6.3vw;
    height: 5.99vw;
  }
  .menue__spenden {
    margin-top: 5.47vw;
    font-size: 2.19vw;
    line-height: 2.29vw;
    font-weight: 700;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    height: 13.75vw;
    width: 13.75vw;
    z-index: 1;
  }
  .menue__links {
    left: 2.86vw;
    position: fixed;
    top: 2.6vw;
    z-index: 1;
    height: calc(100vh - 2.6vw);
  }
  .menue__gruppe--1-1 {
    font-size: 1.77vw;
    line-height: 2.14vw;
  }
  .menue__gruppe--1-2 {
    margin-top: 1.25vw;
    font-size: 1.35vw;
    line-height: 1.67vw;
    font-weight: 400;
  }
  .menue__gruppe--1-3 {
    font-size: 0.94vw;
    line-height: 1.04vw;
    font-weight: 400;
    bottom: 3.18vw;
    left: 0;
    position: absolute;
  }
  .menue__link {
    display: inherit;
    text-decoration: none;
    position: relative;
  }
  .menue__link--aktiv {
    font-weight: 500;
  }
  .menue__hamburger {
    display: none;
  }
}
.spenden {
  font-family: FilsonPro, sans-serif;
  font-weight: 700;
  background-color: #E61512;
  color: #fff;
  text-decoration: none;
}

@media (max-width: 799px) {
  .spenden {
    padding: 0px 20px 0px 20px;
    font-size: 20px;
    line-height: 20px;
    height: 50px;
    display: inline-flex;
    text-align: center;
    align-items: center;
    border-radius: 50px;
  }
}
@media (min-width: 800px) {
  .spenden {
    margin-top: -5.73vw;
    padding: 0vw 1.82vw 0vw 1.82vw;
    font-size: 1.77vw;
    line-height: 1.77vw;
    height: 5.42vw;
    display: inline-flex;
    text-align: center;
    align-items: center;
    border-radius: 2.6vw;
  }
}
.startseite {
  font-family: FilsonPro, sans-serif;
}
.startseite__introtext {
  color: #E61512;
}
.startseite__text a {
  color: black;
}

@media (max-width: 799px) {
  .startseite__introtext {
    margin: 87px 0px 103px 0px;
    font-size: 60px;
    line-height: 51px;
    font-weight: 700;
  }
  .startseite__text h2 {
    padding-bottom: 53px;
    font-size: 28px;
    line-height: 31px;
    font-weight: 700;
  }
  .startseite__text h3 {
    padding-bottom: 40px;
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
  }
  .startseite__text p {
    padding-bottom: 35px;
    font-size: 20px;
    line-height: 27px;
    font-weight: 400;
  }
  .startseite__text a {
    padding-bottom: 35px;
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
  }
}
@media (min-width: 800px) {
  .startseite__introtext {
    margin-bottom: 23.85vw;
    font-size: 15.63vw;
    line-height: 13.13vw;
    font-weight: 700;
    margin-left: calc((100% - 0vw - 8.3vw) / 9 * -3 - 3.32vw + 0vw);
  }
  .startseite__text {
    margin-left: calc((100% - 0vw - 8.3vw) / 9 * -1 - 0.83vw + 0vw);
  }
  .startseite__text h2 {
    padding-bottom: 2.6vw;
    font-size: 3.65vw;
    line-height: 3.91vw;
    font-weight: 700;
  }
  .startseite__text h3 {
    padding-bottom: 2.08vw;
    font-size: 1.46vw;
    line-height: 1.67vw;
    font-weight: 700;
  }
  .startseite__text p {
    padding-bottom: 2.08vw;
    font-size: 1.46vw;
    line-height: 1.67vw;
    font-weight: 400;
  }
  .startseite__text a {
    padding-bottom: 2.08vw;
    font-size: 1.46vw;
    line-height: 1.67vw;
    font-weight: 700;
  }
}
.text-breit {
  font-family: Clarendon, serif;
}
.text-breit:last-child {
  margin-bottom: 0;
}
.text-breit__titel {
  font-family: FilsonPro, sans-serif;
  font-weight: 400;
}
.text-breit__text a {
  font-family: FilsonPro, sans-serif;
  color: black;
  font-weight: 700;
}
.body--kontakt .text-breit__text a {
  font-family: Clarendon, serif;
  font-weight: 400;
  text-decoration: none;
}
.text-breit__text a:hover {
  color: #E61512;
}
.text-breit__text a strong {
  font-family: FilsonPro, sans-serif;
  font-weight: 700;
}
.text-breit__text h4 {
  font-family: FilsonPro, sans-serif;
  font-weight: 700;
}
.text-breit__text ul a:hover {
  color: #E61512;
}
.text-breit__text h4 + ul {
  margin-top: 0px;
}
.text-breit__text strong {
  font-family: FilsonPro, sans-serif;
  font-weight: 700;
}

@media (max-width: 799px) {
  .text-breit {
    margin: 55px 0px 55px 0px;
  }
  .text-breit__titel {
    margin: 0px 0px 40px 0px;
    font-size: 26px;
    line-height: 28px;
    font-weight: 700;
  }
  .text-breit__text {
    font-size: 20px;
    line-height: 27px;
    font-weight: 400;
  }
  .text-breit__text p {
    margin-top: 18px;
  }
  .text-breit__text h4 {
    margin-top: 18px;
  }
  .text-breit__text ul {
    margin-top: 18px;
  }
  .text-breit__text li {
    padding: 0px 0px 0px 17px;
  }
  .text-breit__text li::before {
    top: -1px;
  }
  .text-breit__bild {
    margin: 50px 0px 14px 0px;
  }
  .text-breit__bild-figcaption {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
  }
}
@media (min-width: 800px) {
  .text-breit {
    margin: 9.9vw 0vw 9.9vw 0vw;
    width: calc((100% - 0vw - 6.64vw) / 9 * 6 + 4.15vw + 0vw);
  }
  .text-breit__titel {
    margin: 0vw 0vw 2.19vw 0vw;
    font-size: 1.77vw;
    line-height: 1.88vw;
    font-weight: 700;
  }
  .text-breit__text {
    font-size: 1.15vw;
    line-height: 1.56vw;
    font-weight: 400;
  }
  .text-breit__text p {
    margin-top: 1.04vw;
  }
  .text-breit__text h4 {
    margin-top: 1.04vw;
  }
  .text-breit__text ul {
    margin-top: 1.04vw;
  }
  .text-breit__text li {
    padding: 0vw 0vw 0vw 0.89vw;
  }
  .text-breit__text li::before {
    top: -0.05vw;
  }
  .text-breit__bild {
    margin: 4.17vw 0vw 0.78vw 0vw;
  }
  .text-breit__bild-figcaption {
    font-size: 0.83vw;
    line-height: 1.04vw;
    font-weight: 400;
  }
}
.text-schmal {
  font-family: Clarendon, serif;
  display: flex;
}
.text-schmal:last-child {
  margin-bottom: 0;
}
.text-schmal__titel {
  font-family: FilsonPro, sans-serif;
  font-weight: 400;
}
.text-schmal__text > *:first-child {
  margin-top: 0;
}
.text-schmal a {
  color: black;
  font-family: FilsonPro, sans-serif;
  font-weight: 700;
}
.body--kontakt .text-schmal a {
  font-family: Clarendon, serif;
  font-weight: 400;
  text-decoration: none;
}
.text-schmal a:hover {
  color: #E61512;
}
.text-schmal a strong {
  font-family: FilsonPro, sans-serif;
  font-weight: 700;
}
ul .text-schmal a {
  color: #E61512;
}
.text-schmal strong {
  font-family: FilsonPro, sans-serif;
  font-weight: 700;
}

@media (max-width: 799px) {
  .text-schmal {
    margin: 40px 0px 40px 0px;
    font-size: 20px;
    line-height: 27px;
    font-weight: 400;
    flex-direction: column;
  }
  .text-schmal__bild {
    margin: 0px 0px 40px 0px;
    order: 1;
  }
  .text-schmal--0 .text-schmal__bild {
    margin-left: -26px;
  }
  .text-schmal--1 .text-schmal__bild {
    margin-right: -26px;
  }
  .text-schmal__container {
    order: 3;
  }
  .text-schmal__titel {
    margin: 0px 0px 40px 0px;
    font-size: 26px;
    line-height: 28px;
    order: 2;
  }
  .text-schmal__text p {
    margin-top: 18px;
  }
  .text-schmal__text ul {
    margin-top: 18px;
  }
  .text-schmal__text li {
    padding: 0px 25px 0px 17px;
  }
  .text-schmal__text li::before {
    top: -1px;
  }
  .text-schmal__links {
    margin-top: 60px;
  }
  .text-schmal__links ul {
    margin-bottom: -15px;
  }
  .text-schmal__links li {
    padding: 0px 0px 15px 17px;
  }
  .text-schmal__links li::before {
    top: -1px;
  }
}
@media (min-width: 800px) {
  .text-schmal {
    margin: 9.9vw 0vw 9.9vw 0vw;
    font-size: 1.15vw;
    line-height: 1.56vw;
    font-weight: 400;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .text-schmal__bild {
    width: calc((100% - 0vw - 6.64vw) / 9 * 5 + 3.32vw + 0vw);
  }
  .text-schmal--0 .text-schmal__bild {
    margin-left: -3.7vw;
    order: 2;
  }
  .text-schmal--1 .text-schmal__bild {
    margin-right: -3.7vw;
    order: 3;
  }
  .text-schmal__container {
    width: calc((100% - 0vw - 6.64vw) / 9 * 4 + 2.49vw + 0vw);
  }
  .text-schmal--0 .text-schmal__container {
    order: 3;
  }
  .text-schmal--1 .text-schmal__container {
    order: 2;
  }
  .text-schmal__titel {
    margin-bottom: 2.81vw;
    font-size: 1.77vw;
    line-height: 1.88vw;
    order: 1;
    width: 100%;
  }
  .text-schmal--0 .text-schmal__titel {
    padding-left: calc((100% - 0vw - 6.64vw) / 9 * 5 + 4.15vw + 0vw);
  }
  .text-schmal__text p {
    margin-top: 1.04vw;
  }
  .text-schmal__text ul {
    margin-top: 1.04vw;
  }
  .text-schmal__text ul li {
    padding: 0vw 0vw 0vw 0.89vw;
  }
  .text-schmal__text ul li::before {
    top: -0.05vw;
  }
  .text-schmal__links {
    margin-top: 3.39vw;
  }
  .text-schmal__links ul {
    margin-bottom: -0.78vw;
  }
  .text-schmal__links ul li {
    padding: 0vw 0vw 0.78vw 0.89vw;
  }
  .text-schmal__links ul li::before {
    top: -0.05vw;
  }
}
.titel {
  font-family: FilsonPro, sans-serif;
}

@media (max-width: 799px) {
  .titel {
    margin-bottom: 91px;
    font-size: 34px;
    line-height: 34px;
    font-weight: 400;
    display: block;
  }
}
@media (min-width: 800px) {
  .titel {
    margin-bottom: 9.9vw;
    display: none;
  }
}
.macro-bild {
  position: relative;
  display: block;
  height: 0;
  width: 100%;
}
.macro-bild .lazyload,
.macro-bild .lazyloading {
  opacity: 0;
}
.macro-bild .lazyloaded {
  transition: background-color 500ms ease-in;
  opacity: 1;
  background-color: transparent;
  /*  transition is applied to lazyloaded not lazyload */
  transition: opacity 500ms ease-in;
}

.macro-bild__img,
.macro-bild__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

body[data-menue-aktiv=true] {
  position: fixed;
  width: 100%;
}
body[data-menue-aktiv=true] .main {
  pointer-events: none;
}

.macro-video {
  position: relative;
  overflow: hidden;
}
.macro-video__iframe, .macro-video iframe {
  background-color: transparent;
  border: none;
  height: 100%;
  left: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.seite {
  position: relative;
}

@media (max-width: 799px) {
  .seite {
    width: 100%;
    height: 100%;
  }
}
.main {
  position: relative;
  hyphens: auto;
}
.main > * {
  margin-top: 0px;
}

@media (max-width: 799px) {
  .main {
    padding: 114px 26px 110px 26px;
    min-height: 100vh;
  }
}
@media (min-width: 800px) {
  .main {
    padding: 21.35vw 7.29vw 9.9vw 28.85vw;
    min-height: 100vh;
  }
}