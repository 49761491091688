.text-breit {
    font-family: Clarendon, serif;

    &:last-child {
        margin-bottom: 0;
    }

    &__titel {
        font-family: FilsonPro, sans-serif;
        font-weight: 400;
    }
    
    &__text {

        a {
            font-family: FilsonPro, sans-serif;
            color: black;
            font-weight: 700;

            .body--kontakt & {
                font-family: Clarendon, serif;
                font-weight: 400;
                text-decoration: none;
            }

            &:hover {
                color: map-get($farben, rot);
            }

            strong {
                font-family: FilsonPro, sans-serif;
                font-weight: 700;
            }
        }

        h4 {
            font-family: FilsonPro, sans-serif;
            font-weight: 700;
        }

        & ul {

            & a {
                &:hover {
                    color: map-get($farben, rot);
                }
            }
        }


        h4 + ul {
           margin-top: 0px;     
        }

        strong {
            font-family: FilsonPro, sans-serif;
            font-weight: 700;
        }
    }
}


@include mediaquery(smartphone) {
    
    $layout: smartphone;
    
    .text-breit {
        @include abstand($layout, margin, alle, 55, 0, 55, 0);

        &__titel {
            @include abstand($layout, margin, alle, 0, 0, 40, 0);
            @include schriftart($layout, 26, 28, 700);
        }

        &__text {
            @include schriftart($layout, 20, 27, 400);

            p {
                @include abstand($layout, margin, top, 18);
            }

            h4 {
                @include abstand($layout, margin, top, 18);
            }

            ul {
                @include abstand($layout, margin, top, 18);
            }

            li {
                @include abstand($layout, padding, alle, 0, 0, 0, 17);
                
                &::before {
                    top: groesse($layout, -1);
                }
            }
        }

        &__bild {
            @include abstand($layout, margin, alle, 50, 0, 14, 0);
        }

        &__bild-figcaption {
            @include schriftart($layout, 18, 22, 400);
        }
    }
}


@include mediaquery(desktop) {
    
    $layout: desktop;
    
    .text-breit {
        @include abstand($layout, margin, alle, 190, 0, 190, 0);
        width: spalten($layout, 6, 9, 0, 0);
        
        &__titel {
            @include abstand($layout, margin, alle, 0, 0, 42, 0);
            @include schriftart($layout, 34, 36, 700);
        }

        &__text {
            @include schriftart($layout, 22, 30, 400);

            p {
                @include abstand($layout, margin, top, 20);
            }

            h4 {
                @include abstand($layout, margin, top, 20);
            }

            ul {
                @include abstand($layout, margin, top, 20);
            }
            
            li {
                @include abstand($layout, padding, alle, 0, 0, 0, 17);
                
                &::before {
                    top: groesse($layout, -1);
                }
            }
        }
        
        &__bild {
            @include abstand($layout, margin, alle, 80, 0, 15, 0);
        }

        &__bild-figcaption {
            @include schriftart($layout, 16, 20, 400);
        }
    }
}