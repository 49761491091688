.spenden {
    font-family: FilsonPro, sans-serif;
    font-weight: 700;
    background-color: map-get($farben, rot);
    color: #fff;
    text-decoration: none;
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .spenden {
        @include abstand($layout, padding, alle, 0, 20, 0, 20);
        @include schriftart($layout, 20, 20);
        height: groesse($layout, 50);
        display: inline-flex;
        text-align: center;
        align-items: center;
        border-radius: groesse($layout, 50);
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .spenden {
        @include abstand($layout, margin, top, 80 - 190);
        @include abstand($layout, padding, alle, 0, 35, 0, 35);
        @include schriftart($layout, 34, 34);
        height: groesse($layout, 104);
        display: inline-flex;
        text-align: center;
        align-items: center;
        border-radius: groesse($layout, 50);
    }

}